import { Outlet, RouteObject } from "react-router-dom";
import { authloader } from "..";
import ErrorPage from "../../pages/error-page";
import Overview from "./index";
import IncomingShipping from "./incomingShipping/incomingShipping";
import CartToLogistic from "./incomingShipping/cartToLogistic/cartToLogistic";
import QuoteEdit from "./quoteEdit/quoteEdit";
import OperationControl from "./qualityControl/QualityControl";
import ReadProduct from "./readProduct/readProduct";
import ProShipping from "./proShipping/proShipping";
import ProIncomingShipping from "./proShipping/proIncomingShipping/proIncomingShipping";
import ProOutgoingShipping from "./proShipping/proOutgoingShipping/proOutgoingShipping";
import CreateChronopost from "./createChronopost/createChronopost";
import Climbing from "./workshop/climbing/Climbing";
import Workshop from "./workshop/workshop";
import Cobbler from "./workshop/cobbler/Cobbler";
import Leather from "./workshop/leather/Leather";
import SearchComponent from "./searchComponent/SearchComponent";

export const route: RouteObject = {
  path: "/quick-access",
  element: <Outlet />,
  errorElement: <ErrorPage />,
  loader: authloader,
  children: [
    {
      index: true,
      element: <Overview />,
      loader: authloader,
    },
    {
      path: "incoming-shipping",
      element: <IncomingShipping />,
      loader: authloader,
    },
    {
      path: "pro-incoming-shipping/:shopId",
      element: <ProIncomingShipping />,
      loader: authloader,
    },
    {
      path: "pro-outgoing-shipping/:shopId",
      element: <ProOutgoingShipping />,
      loader: authloader,
    },
    {
      path: "move-product",
      element: (
        <SearchComponent title={"Déplacer des articles"} query={""} to={""} />
      ),
      loader: authloader,
    },
    {
      path: "cart-to-logistic/:trackId",
      element: <CartToLogistic />,
      loader: authloader,
    },
    {
      path: "quote-edit/:trackId",
      element: <QuoteEdit />,
      loader: authloader,
    },
    {
      path: "quality-control",
      element: (
        <SearchComponent
          title={"Contrôler un article"}
          query={"&productsList.status=WORKSHOP_COMPLETE"}
          to={"/quick-access/quality-control-section/"}
        />
      ),
      loader: authloader,
    },
    {
      path: "search-product",
      element: (
        <SearchComponent
          title={"Chercher un article"}
          query={"&status=LOGISTIC_WORKSHOP"}
          to={"/quick-access/search-product/"}
        />
      ),
      loader: authloader,
    },
    {
      path: "search-product/:productId",
      element: <ReadProduct />,
      loader: authloader,
    },
    {
      path: "quality-control-section/:productId",
      element: <OperationControl />,
      loader: authloader,
    },
    {
      path: "quote-edit-search",
      element: (
        <SearchComponent
          title={"Rédiger un devis"}
          query={""}
          to={"/quick-access/quote-edit/"}
        />
      ),
      loader: authloader,
    },
    {
      path: "pro-shipping",
      element: <ProShipping />,
      loader: authloader,
    },
    {
      path: "create-chronopost",
      element: <CreateChronopost />,
      loader: authloader,
    },
    {
      path: "workshop/climbing",
      element: <Climbing />,
      loader: authloader,
    },
    {
      path: "workshop",
      element: <Workshop />,
      loader: authloader,
    },
    {
      path: "workshop/climbing",
      element: <Climbing />,
      loader: authloader,
    },
    {
      path: "workshop/cobbler",
      element: <Cobbler />,
      loader: authloader,
    },
    {
      path: "workshop/leather",
      element: <Leather />,
      loader: authloader,
    },
  ],
};
