import { useCallback, useState } from "react";
import { useQuery } from "react-query";
import {
  getProductsReport,
  ProductReportResponse,
} from "../../requests/reports";
import style from "./index.module.scss";
import clsx from "clsx";
import translate from "../../translation";
import DatePicker from "../../components/datePicker/datePicker";

function PayedProductsTable({
  dateFrom,
  dateTo,
}: {
  dateFrom: Date;
  dateTo: Date;
}) {
  const { data } = useQuery({
    queryKey: [
      "payed-product-report",
      {
        dateFrom,
        dateTo,
        eventDescription: "BOOKING_PAYMENT_RECEIVED",
        eventService: "ms-accounting",
      },
    ],
    queryFn: getProductsReport,
    select: (data) => {
      return {
        data: data.data.sort((a, b) =>
          translate(a.itemName).localeCompare(translate(b.itemName), "fr")
        ),
      };
    },
  });

  return <ProductsTable title={"Articles Payés"} data={data?.data || []} />;
}

function DoneProductsTable({
  dateFrom,
  dateTo,
}: {
  dateFrom: Date;
  dateTo: Date;
}) {
  const { data } = useQuery({
    queryKey: [
      "done-product-report",
      {
        dateFrom,
        dateTo,
        eventDescription: "LOGISTIC_DONE",
        eventService: "ms-logistic",
      },
    ],
    queryFn: getProductsReport,
    select: (data) => {
      return {
        data: data.data.sort((a, b) =>
          translate(a.itemName).localeCompare(translate(b.itemName), "fr")
        ),
      };
    },
  });

  return (
    <ProductsTable title={"Articles controllés"} data={data?.data || []} />
  );
}

function ProductsTable({
  title,
  data,
}: { title: string } & ProductReportResponse) {
  return (
    <div className={style["product-table"]}>
      <div className={clsx(style["product-table-title"])}>{title}</div>
      <div className={style["product-table-grid"]}>
        <div
          className={clsx(
            style["product-table-item"],
            style["product-table-item-name"],
            style["product-table-header"]
          )}
        >
          Nom
        </div>
        <div
          className={clsx(
            style["product-table-item"],
            style["product-table-item-count"],
            style["product-table-header"]
          )}
        >
          Total
        </div>
        <div
          className={clsx(
            style["product-table-item"],
            style["product-table-item-price"],
            style["product-table-header"]
          )}
        >
          Prix TTC
        </div>
        {data.map((item) => (
          <>
            <div
              className={clsx(
                style["product-table-item"],
                style["product-table-item-name"]
              )}
            >
              {translate(item.itemName)}
            </div>
            <div
              className={clsx(
                style["product-table-item"],
                style["product-table-item-count"]
              )}
            >
              {item.itemCount}
            </div>
            <div
              className={clsx(
                style["product-table-item"],
                style["product-table-item-price"]
              )}
            >
              {item.totalPrice}
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

function formatDate(date: string | undefined) {
  return date !== ""
    ? date && new Date(date).toISOString().substring(0, 10)
    : "";
}

function DateRange({
  dateFrom,
  dateTo,
  onChange,
}: {
  dateFrom: Date;
  dateTo: Date;
  onChange: ({ dateFrom, dateTo }: { dateFrom: Date; dateTo: Date }) => void;
}) {
  return (
    <div className={style["date-range-picker-container"]}>
      <div className={style["shipping-date"]}>
        <label>Du</label>
        <input
          name={"dateFrom"}
          type="date"
          lang="FR"
          max={formatDate(dateTo.toISOString())}
          value={formatDate(dateFrom.toISOString())}
          onChange={handleChange}
          className={style["date-input"]}
        />
      </div>
      <div className={style["shipping-date"]}>
        <label>Au</label>
        <input
          name={"dateTo"}
          type="date"
          lang="FR"
          min={formatDate(dateFrom.toISOString())}
          value={formatDate(dateTo.toISOString())}
          onChange={handleChange}
          className={style["date-input"]}
        />
      </div>
    </div>
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    let dateRange = { dateFrom, dateTo };
    dateRange[event.target.name as keyof typeof dateRange] = new Date(
      event.target.value
    );
    onChange && onChange(dateRange);
  }
}
export default function Reports() {
  const [dateRange, setDateRange] = useState({
    dateFrom: new Date(new Date().getTime() - 86400 * 1000 * 7),
    dateTo: new Date(),
  });

  function handleDateChange(dateRange: { dateFrom: Date; dateTo: Date }): void {
    setDateRange(dateRange);
  }

  return (
    <div className={style["container"]}>
      <div className={style["date-range-container"]}>
        <DateRange
          dateFrom={dateRange.dateFrom}
          dateTo={dateRange.dateTo}
          onChange={handleDateChange}
        />
      </div>
      <div className={style["tables-container"]}>
        <PayedProductsTable
          dateFrom={dateRange.dateFrom}
          dateTo={dateRange.dateTo}
        />
        <DoneProductsTable
          dateFrom={dateRange.dateFrom}
          dateTo={dateRange.dateTo}
        />
      </div>
    </div>
  );
}
