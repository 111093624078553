import { QueryFunctionContext } from "react-query";

export interface ProductReportResponse {
  data: { itemName: string; totalPrice: number; itemCount: number }[];
}

export interface ProductReportRequest {
    dateFrom: Date;
    dateTo: Date;
    eventDescription: string;
    eventService: string;
  }

export async function getProductsReport({
  queryKey,
}: QueryFunctionContext<[string, ProductReportRequest],any
>): Promise<ProductReportResponse> {
  const [_key, { dateFrom, dateTo, eventDescription, eventService }] = queryKey;
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "GET",
  };

  try {
    const query = new URLSearchParams(
      {
        dateFrom: dateFrom.toISOString(),
        dateTo: dateTo.toISOString(),
        eventDescription,
        eventService,
      }
    ).toString();

    console.debug(query)
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/reports/products?${query}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}
